.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--white) !important;
    background-color: var(--uva-dark-blue);
    padding: 1rem;
    border: 1px solid black;
    width: 100%;
}

.navbar-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.navbar-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}

.sabers-logo {
    width: 5rem;
    height: 5rem;
    margin-left: auto;
}

.MuiButton-root {
    color: var(--white) !important;
    font-size: 35px !important;
    margin-right: 1rem !important;
}

.navbar-mobile{
    display: none;
}

.navbar-mobile-hamburgerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    transition: 0.2s ease-in-out;
}

.navbar-mobile-hamburgerWrapper > div {
    width: 1.5rem;
    height: 0.2rem;
    background-color: var(--white);
    border-radius: 10px;
}

@media only screen and (width < 1020px) {
    .MuiButton-root {
        margin-right: 0 !important;
    }
    .navbar-left {
        gap: .5rem;
    }
    .navbar-right {
        gap: .5rem;
    }
}

@media only screen and (width < 890px) {
    .navbar-left {
        display: none;
    }
    .navbar-right {
        width: 40px;
        height: 40px;
    }
    .navbar-mobile {
        display: flex;
    }
}