.accomplishments-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--uva-orange);
    color: var(--white);
    font-size: 20px;
    margin-bottom: 20px;
}

.accomplishment {
    display: flex;
    align-items: center;
    padding: 40px;
    background-color: var(--uva-dark-blue);
    color: var(--white);
    margin: 20px;
    border-radius: 10px;
}

.accomplishment img {
    max-width: 150px;
    min-height: 125px;
    margin-right: 30px;
}

.accomplishment-details {
    flex: 1;
}

.accomplishment-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.accomplishment-description {
    font-size: 18px;
    font-family: var(--font-secondary) !important;
}

.article {
    color: var(--white);
}

@media screen and (max-width: 895px) {
    .accomplishment {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .accomplishment img {
        margin-right: 0;
        margin-bottom: 20px;
    }
}