/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/

*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
input:focus {
  outline: none;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap');



/* Custom CSS */

:root{
  --uva-dark-blue: #232D4B;
  --uva-orange: #F84C1E;
  --white: #FFFFFF;

  --font-family: 'Bebas Neue', sans-serif;
  --font-secondary: 'Montserrat', sans-serif;
  --font-weight: 400; /* change to your font weight */
  /* --font-style: some-font-style; change to your font style */
}

*{
  /* applies your font, font-weight and font-style to all elements */
  font-family: 'Bebas Neue', sans-serif !important;
  font-weight: var(--font-weight);
  /* font-style: var(--font-style); */
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}