.schedule-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--uva-orange);
    color: var(--white);
    font-size: 20px;
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: auto;
    margin-right: auto;
    margin-left: 20px;
    margin-bottom: 20px;
}

.info {
    font-family: var(--font-secondary) !important;
}