.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--white) !important;
    background-color: var(--uva-dark-blue);
    padding: 1rem;
    border: 1px solid black;
    width: 100%;
    bottom: 0;
}

.sabers-logo {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
    margin-left: 0;
}