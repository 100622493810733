.meetTheTeam-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--uva-orange);
    color: var(--white);
    font-size: 20px;
}

.body {
    color: var(--uva-dark-blue);
}

.info {
    font-family: var(--font-secondary) !important;
}