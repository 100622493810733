.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--uva-orange);
    color: var(--white);
    font-size: 20px;
    margin-bottom: 20px;
}

.our-story {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 0;
    margin-left: auto;
    width: 75%;
    color: var(--white);
    background-color: var(--uva-dark-blue);
    border: 5px solid var(--uva-orange);
}

.the-need {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: auto;
    margin-left: 0;
    width: 75%;
    color: var(--white);
    background-color: var(--uva-dark-blue);
    border: 5px solid var(--uva-orange);
}

.pedigree {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 0;
    margin-left: auto;
    width: 75%;
    color: var(--white);
    background-color: var(--uva-dark-blue);
    border: 5px solid var(--uva-orange);
}

.left-image {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: 20px;
    width: 250%;
    height: 250%;
}

.left-text {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 50px;
    margin-left: 20px;
}

.right-image {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: auto;
    width: 250%;
    height: 250%;
}

.right-text {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 50px;
}

.info {
    font-family: var(--font-secondary) !important;
}

.links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
    color: var(--white);
}

.button-link {
    display: flex;
    flex-direction: row;
    padding: 15px 25px;
    border-radius: 30px;
    background-color: var(--uva-dark-blue);
    color: var(--white);
    text-decoration: none;
    font-size: 20px;
    margin: 10px;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.button-link:hover {
    background-color: var(--uva-orange);
}


.icon {
    width: 28px;
    height: 28px;
    vertical-align: middle;
    margin-right: 15px;
}

@media screen and (max-width: 895px) {
    .our-story {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: auto;
        margin: 10px;
    }

    .right-image {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 20px;
        width: auto;
    }

    .left-text {
        margin-right: auto;
        margin-left: auto;
    }

    .the-need {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: auto;
        margin: 10px;
    }

    .left-image {
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        width: auto;
    }

    .right-text {
        margin-right: 20px;
        margin-left: 20px;
    }

    .pedigree {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: auto;
        margin: 10px;
    }
    
}