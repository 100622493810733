.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--uva-orange);
    color: var(--white);
    font-size: 20px;
}

.links-home {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
    color: var(--white);
}

.button-link-home {
    display: flex;
    flex-direction: row;
    padding: 15px 25px;
    border-radius: 30px;
    background-color: var(--uva-dark-blue);
    color: var(--white);
    text-decoration: none;
    font-size: 20px;
    margin: 10px;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.button-link-home:hover {
    background-color: var(--uva-orange);
}


.icon-home {
    width: 28px;
    height: 28px;
    vertical-align: middle;
    margin-right: 15px;

}
  
.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    padding: 20px;
    margin: auto;
}
  
.slider {
    overflow: hidden;
    position: relative;
    width: 100%;}
  
.slide-wrapper {
    display: flex;
    transition: transform 0.5s ease;
    align-items: center;
}
  
.slide {
    flex: 0 0 100%;
}
  
.full-image {
    width: 100%;
    height: auto;
}
  
.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
  
.dot {
    width: 12px;
    height: 12px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 0.5rem;
    cursor: pointer;
}
  
.dot.active {
    background-color: #333;
}

@media only screen and (width < 895px) {
    .body {
        width: 100%;
    }
}
  